















import { Component, Vue } from 'vue-property-decorator';
import { readUserProfile } from '@/store/main/getters';
import { quickSightDashboardUrl } from '@/env';

@Component
export default class Dashboard extends Vue {
  public quickSightDashboardUrl = quickSightDashboardUrl;

  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }
  }
}
